import { FC, Fragment } from 'react';

import { Typography } from '@letsdance/ui-kit';
import { AUTOMATION } from '@widgets/Landing/const/teachers-text.const';

import cls from './styles.module.scss';

export const Automation: FC = () => (
  <section className={cls.automation}>
    {AUTOMATION &&
      AUTOMATION.map(([part, title, desc], index) => (
        <Fragment key={title}>
          <div className={cls.automation__item}>
            <Typography variant={'head-32'} className={cls.automation__title}>
              <span>{part}</span> {''}
              {title}
            </Typography>
            <Typography variant={'body-18'}>{desc}</Typography>
          </div>
          {AUTOMATION.length - 1 !== index && (
            <span className={cls.divider}></span>
          )}
        </Fragment>
      ))}
  </section>
);
