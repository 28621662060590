import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Typography } from '@letsdance/ui-kit';
import { CheckIcon } from '@widgets/Landing/ui/icons';
import cls from '@widgets/Landing/ui/MainUi/Benefit/styles.module.scss';

interface BenefitMessagesProps {
  messages: string[];
}

export const BenefitMessages: FC<BenefitMessagesProps> = ({ messages }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <div>
      {messages.map((message) => (
        <div key={message} className={cls.benefit__item}>
          <CheckIcon className={cls.benefit__icon} />
          <Typography
            variant={isDesktop ? 'body-18' : 'body-16'}
            className={cls.benefit__text}>
            {message}
          </Typography>
        </div>
      ))}
    </div>
  );
};
