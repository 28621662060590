import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Divider, Typography } from '@letsdance/ui-kit';
import {
  ANALITICS,
  INFORMATION,
  LOYALTY_PROGRAMS,
} from '@widgets/Landing/const/teachers-text.const';
import { CheckIcon } from '@widgets/Landing/ui/icons';
import { Telegram } from '@widgets/Landing/ui/icons/Telegram';

import cls from './styles.module.scss';

export const Benefit: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.benefit}>
      <Typography
        variant={isDesktop ? 'head-56' : 'head-32'}
        className={cls.benefitTitle}>
        Преимущества <br /> и возможности платформы
      </Typography>
      <div className={cls.wrapper}>
        <div className={cls.card}>
          <div className={cls.cardGroup}>
            <span className={cls.icon}>📈</span>
            <Typography
              variant={isDesktop ? 'head-32' : 'head-20'}
              className={cls.cardTitle}>
              Статистика и аналитика
            </Typography>
          </div>
          <ul>
            {ANALITICS.map((item) => (
              <li key={item} className={cls.card__item}>
                <CheckIcon className={cls.cardCheck} />
                <Typography variant={isDesktop ? 'body-18' : 'body-16'}>
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
        <div className={cls.imgWrapper}>
          <img
            src="./finance.png"
            alt="Финансы"
            className={cls.img}
            loading="lazy"
          />
        </div>
      </div>
      <Divider className={cls.divider} />
      <div className={cls.cards}>
        {INFORMATION.map((item) => (
          <div key={item.title} className={cls.item}>
            <Typography
              variant={isDesktop ? 'head-32' : 'head-20'}
              className={cls.itemTitle}>
              {item.icon ? (
                <span className={cls.icon}>{item.icon}</span>
              ) : (
                <Telegram fill={'#404040'} bg={'#fff'} className={cls.tgIcon} />
              )}
              {item.title}
            </Typography>
            <Typography variant={isDesktop ? 'body-18' : 'body-16'}>
              {item.desc}
            </Typography>
          </div>
        ))}
      </div>
      <Divider className={cls.divider} />
      <div className={cls.wrapper}>
        <div className={cls.card}>
          <div className={cls.cardGroup}>
            <span className={cls.icon}>🎁</span>
            <Typography
              variant={isDesktop ? 'head-32' : 'head-20'}
              className={cls.cardTitle}>
              Программы лояльности
            </Typography>
          </div>
          <ul>
            {LOYALTY_PROGRAMS.map((item) => (
              <li key={item} className={cls.card__item}>
                <CheckIcon className={cls.cardCheck} />
                <Typography variant={isDesktop ? 'body-18' : 'body-16'}>
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
        <div className={cls.imgWrapper}>
          <img
            src="./course.png"
            alt="Финансы"
            className={cls.img}
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
};
