import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { Button, Typography } from '@letsdance/ui-kit';
import { LandingLogoIcon } from '@shared/ui/icons/LandingLogo';

import cls from './styles.module.scss';

export const MainScreenLanding: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <LandingLogoIcon className={cls.logo} />
      <Typography
        variant={isDesktop ? 'head-32' : 'head-24'}
        className={cls.title}>
        Платформа для запуска ваших онлайн-курсов в Telegram
      </Typography>
      <div className={cls.section__controls}>
        <Button
          fullWidth={!isDesktop}
          className={cx(cls.btn, cls.btn_red)}
          onClick={() =>
            window.open(
              'https://t.me/h2dance_bot',
              '_blank',
              'noopener,noreferrer',
            )
          }>
          <Typography variant={'head-16'}>Попробовать</Typography>
        </Button>
      </div>
      <img alt="" src="./mainImage.png" loading="lazy" className={cls.image} />
    </section>
  );
};
