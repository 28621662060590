import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button, Typography } from '@letsdance/ui-kit';
import { CheckIcon, PromoBackground } from '@widgets/Landing/ui/icons';

import cls from './styles.module.scss';

export const Promo: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 820px)' });
  const title = isDesktop
    ? 'Интеграция с информационным порталом «Где танцевать»'
    : `Интеграция <br /> с информационным <br /> порталом <br /> «Где танцевать»`;

  return (
    <div className={cls.promo}>
      <PromoBackground isDesktop={isDesktop} className={cls.promoBackground} />
      <div className={cls.promoDesc}>
        <Typography
          variant={isDesktop ? 'head-32' : 'head-24'}
          className={cls.promoTitle}>
          <span dangerouslySetInnerHTML={{ __html: title || '' }}></span>
        </Typography>
        <ul className={cls.promoList}>
          <li className={cls.promoItem}>
            <CheckIcon color={'#fff'} className={cls.check} />
            <Typography variant={'body-18'}>
              Размещение информации о курсе на информационном портале «Где
              танцевать»
            </Typography>
          </li>
          <li className={cls.promoItem}>
            <CheckIcon color={'#fff'} className={cls.check} />
            <Typography variant={'body-18'}>
              Дополнительный трафик людей для продажи курсов
            </Typography>
          </li>
        </ul>
        <Button
          className={cls.promoBtn}
          onClick={() =>
            window.open('https://t.me/dance88', '_blank', 'noopener,noreferrer')
          }>
          <Typography variant={'head-16'}>Перейти на портал</Typography>
        </Button>
      </div>
      <img src="./promo.png" alt="promo" loading="lazy" className={cls.img} />
    </div>
  );
};
