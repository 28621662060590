export const AUTOMATION = [
  ['на', '85%', 'Нагрузки по администрированию'],
  ['в', '3 раза', 'Сокращение цикла продаж'],
  ['от', '12%', 'Увеличение среднего чека'],
  ['на', '33%', 'Увеличение конверсии'],
];

export const ANALITICS = [
  'Менеджмент клиентской базы с помощью встроенной CRM системы',
  'Статистика заказов по каждому клиенту и анализ их поведения',
];

export const LOYALTY_PROGRAMS = [
  'Увеличение выручки за счёт кэшбэк-сервисов и реферальной системы для дополнительных продаж',
  'Создание промокодов и скидок, кешбэк-система',
  'Реферальные программы для танцевальных информационных порталов, школ танцев',
];

export const INFORMATION = [
  {
    desc: 'Автоматический контроль доступа пользователей в закрытые Telegram каналы и чаты с материалами',
    icon: '⚙️',
    title: 'Автоматизация',
  },
  {
    desc: 'Клиенты могут самостоятельно выбрать и оплатить курс в Telegram боте',
    icon: false,
    title: 'Продажи через Telegram',
  },
  {
    desc: 'Рассылка индивидуальных предложений клиентам',
    icon: '✉️',
    title: 'Рассылка',
  },
];
