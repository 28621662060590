import { FC, SVGProps } from 'react';

type PromoBackgroundProps = SVGProps<SVGSVGElement> & {
  isDesktop?: boolean;
};

export const PromoBackground: FC<PromoBackgroundProps> = ({
  isDesktop,
  ...rest
}) => {
  if (isDesktop) {
    return (
      <svg
        width="914"
        height="378"
        viewBox="0 0 914 378"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}>
        <path
          d="M765.615 299.688L199.497 -266.43L299.271 -366.204L865.389 199.914L765.615 299.688Z"
          fill="url(#paint0_linear_54_2713)"
        />
        <rect
          x="248.05"
          y="714.94"
          width="800.611"
          height="141.107"
          transform="rotate(-45 248.05 714.94)"
          fill="url(#paint1_linear_54_2713)"
        />
        <path
          d="M566.118 299.688L0.000115733 -266.43L99.774 -366.204L665.892 199.914L566.118 299.688Z"
          fill="url(#paint2_linear_54_2713)"
        />
        <rect
          x="48.553"
          y="714.94"
          width="801.267"
          height="141.107"
          transform="rotate(-45 48.553 714.94)"
          fill="url(#paint3_linear_54_2713)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_54_2713"
            x1="815.502"
            y1="249.801"
            x2="249.384"
            y2="-316.317"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F84B07" />
            <stop offset="1" stopColor="#F8D121" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_54_2713"
            x1="248.05"
            y1="785.494"
            x2="1048.66"
            y2="785.494"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F84B07" />
            <stop offset="1" stopColor="#F8D121" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_54_2713"
            x1="616.005"
            y1="249.801"
            x2="49.8871"
            y2="-316.317"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F84B07" />
            <stop offset="1" stopColor="#F8D121" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_54_2713"
            x1="48.553"
            y1="785.494"
            x2="849.82"
            y2="785.494"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F84B07" />
            <stop offset="1" stopColor="#F8D121" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width="328"
      height="596"
      viewBox="0 0 328 596"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M555.559 239.196L144.762 -171.6L217.162 -244L627.958 166.797L555.559 239.196Z"
        fill="url(#paint0_linear_66_5462)"
      />
      <rect
        x="179.996"
        y="540.52"
        width="580.954"
        height="102.392"
        transform="rotate(-45 179.996 540.52)"
        fill="url(#paint1_linear_66_5462)"
      />
      <path
        d="M410.796 239.196L-0.000104707 -171.6L72.3996 -244L483.196 166.797L410.796 239.196Z"
        fill="url(#paint2_linear_66_5462)"
      />
      <rect
        x="35.2334"
        y="540.52"
        width="581.43"
        height="102.392"
        transform="rotate(-45 35.2334 540.52)"
        fill="url(#paint3_linear_66_5462)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_66_5462"
          x1="591.758"
          y1="202.996"
          x2="180.962"
          y2="-207.8"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F84B07" />
          <stop offset="1" stopColor="#F8D121" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_66_5462"
          x1="179.996"
          y1="591.716"
          x2="760.95"
          y2="591.716"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F84B07" />
          <stop offset="1" stopColor="#F8D121" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_66_5462"
          x1="446.996"
          y1="202.996"
          x2="36.1998"
          y2="-207.8"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F84B07" />
          <stop offset="1" stopColor="#F8D121" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_66_5462"
          x1="35.2334"
          y1="591.716"
          x2="616.663"
          y2="591.716"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F84B07" />
          <stop offset="1" stopColor="#F8D121" />
        </linearGradient>
      </defs>
    </svg>
  );
};
