import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';
import {
  Automation,
  Benefit,
  Footer,
  Tasks,
} from '@widgets/Landing/ui/TeachersUi';
import { MainScreenLanding } from '@widgets/Landing/ui/TeachersUi/MainScreenLanding/MainScreenLanding';

import './index.scss';
import cls from './styles.module.scss';

export const TeachersView: FC = (): JSX.Element => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <div className={cls.mainContainer}>
      <header className={cls.header}>
        {isDesktop && (
          <Button
            variant={'outlined'}
            onClick={openSupportTg}
            className={cls.header__button}>
            <Typography tag={'span'} variant={'head-16'}>
              Связаться с нами
            </Typography>
          </Button>
        )}
      </header>
      <div className={cls.container}>
        <MainScreenLanding />
        <main className={cls.main}>
          <Automation />
          <Benefit />
          <Tasks />
        </main>
      </div>
      <div className={isDesktop ? cls.container : ''}>
        <Footer />
      </div>
    </div>
  );
};
