import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Typography } from '@letsdance/ui-kit';
import { Promo } from '@widgets/Landing/ui/TeachersUi/Tasks/Promo';

import cls from './styles.module.scss';

export const Tasks: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.section}>
      <div className={cls.tasks}>
        <Typography
          variant={isDesktop ? 'head-56' : 'head-32'}
          className={cls.tasksTitle}>
          Доверьте нам ваши задачи
        </Typography>
        <div className={cls.list}>
          <div className={cls.item}>
            <span className={cls.wrapper__icon}>⚡</span>
            <div className={cls.group}>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-20'}
                className={cls.cardTitle}>
                Быстрое внедрение
                <br /> за 1–3 дня
              </Typography>
              <Typography
                variant={isDesktop ? 'body-18' : 'body-16'}
                className={cls.tasks__text}>
                Система «под ключ» не потребует доработок
              </Typography>
            </div>
          </div>
          <div className={cls.item}>
            <span className={cls.wrapper__icon}>🕒</span>
            <div className={cls.group}>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-20'}
                className={cls.cardTitle}>
                Круглосуточная помощь
              </Typography>
              <Typography
                variant={isDesktop ? 'body-18' : 'body-16'}
                className={cls.tasks__text}>
                Оперативная поддержка 24/7
              </Typography>
            </div>
          </div>
          <div className={cls.item}>
            <span className={cls.wrapper__icon}>👌</span>
            <div className={cls.group}>
              <Typography
                variant={isDesktop ? 'head-32' : 'head-20'}
                className={cls.cardTitle}>
                Интуитивно понятный интерфейс
              </Typography>
              <Typography
                variant={isDesktop ? 'body-18' : 'body-16'}
                className={cls.tasks__text}>
                Простой и информативный интерфейс, созданный танцорами для
                танцоров
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Promo />
    </section>
  );
};
