import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const Telegram: FC<IconProps & { fill?: string; bg?: string }> = ({
  bg = '#404040',
  fill = '#fff',
  height = 72,
  width = 72,
  ...rest
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}>
    <rect
      x="-0.00244141"
      width="72"
      height="72"
      rx="36"
      fill={bg || '#404040'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6913 33.9669C26.2003 29.3883 33.2079 26.3698 36.7142 24.9115C46.7254 20.7475 48.8056 20.0241 50.1615 20.0003C50.4597 19.995 51.1265 20.0689 51.5584 20.4194C51.9231 20.7153 52.0234 21.1151 52.0714 21.3956C52.1194 21.6762 52.1792 22.3153 52.1317 22.8147C51.5892 28.5149 49.2418 42.3478 48.0475 48.7321C47.5422 51.4335 46.5472 52.3393 45.5839 52.4279C43.4905 52.6206 41.9008 51.0444 39.8733 49.7153C36.7005 47.6355 34.9081 46.3409 31.8283 44.3114C28.2692 41.9659 30.5764 40.6769 32.6048 38.5701C33.1356 38.0188 42.3593 29.6291 42.5378 28.8681C42.5602 28.7729 42.5809 28.4181 42.3701 28.2307C42.1593 28.0434 41.8482 28.1074 41.6237 28.1584C41.3055 28.2306 36.237 31.5807 26.4181 38.2087C24.9794 39.1967 23.6763 39.678 22.5087 39.6528C21.2216 39.625 18.7457 38.925 16.9051 38.3267C14.6475 37.5929 12.8532 37.2049 13.0095 35.9586C13.0909 35.3094 13.9848 34.6455 15.6913 33.9669Z"
      fill={fill || '#fff'}
    />
  </svg>
);
